<template>
  <div>
    <div class="cardTitle">
      <span>兑换商品设置</span>
    </div>
    <searchForm :formItem="formItem" @getSearch="search($event)"></searchForm>
    <a-table
      :columns="columns"
      :data-source="tableData"
      :pagination="pagination"
      :scroll="{ x: 2000 }"
      @change="handleTableChange"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: selectionChoosed,
      }"
      :row-key="
        (record, index) => {
          return record.id;
        }
      "
    >
	  <template slot="type" slot-scope="text, row">
		  {{row.categoryFirstName}} / {{row.categorySecondName}} / {{row.categoryThirdName}}
	  </template>
      <span slot="action" slot-scope="text, row">
        <a-space>
          <a class="ant-dropdown-link" @click="setPoint(row)">设置积分</a>
          <a class="ant-dropdown-link" style="color: red" @click="delPoint(row)">移除</a>
        </a-space>
      </span>
      <span slot="mainPhoto" slot-scope="text, row">
        <img :src="row.mainPhoto" class="table-img" alt="" />
      </span>
    </a-table>
    <!-- <div class="action">
      <a-dropdown :disabled="!hasSelected">
        <a-menu slot="overlay" @click="handleMenuClick">
          <a-menu-item key="on"> 批量上架 </a-menu-item>
          <a-menu-item key="off"> 批量下架 </a-menu-item>
        </a-menu>
        <a-button> 批量操作 <a-icon type="down" /> </a-button>
      </a-dropdown>
      <span style="margin-left: 8px">
        <template v-if="hasSelected">
          {{ `已选择 ${selectedRowKeys.length} 条` }}
        </template>
      </span>
    </div> -->
	<a-drawer title="设置商品积分" :width="540" :visible="pointShow" :body-style="{ paddingBottom: '60px' }" @close="pointClose">
		<div class="drawer-content">
			<a-descriptions layout="vertical" :column="1">
				<a-descriptions-item label="商品名称">
					<div class="item-name">{{pointInfo.skuName}}</div>
				</a-descriptions-item>
				<a-descriptions-item label="商品图片">
					<img :src="pointInfo.mainPhoto" class="info-img" alt="" />
				</a-descriptions-item>
				<a-descriptions-item label="商品价格">
					¥ {{pointInfo.sellPrice}}
				</a-descriptions-item>
				<a-descriptions-item label="商品积分">
					<a-icon type="dollar" theme="twoTone" two-tone-color="rgb(231,198,86)" />
					<a-input-number :min="0" v-model="pointForm.points" style="margin-left:8px">
						
					</a-input-number>
				</a-descriptions-item>
			</a-descriptions>
		</div>
		<div class="drawer-footer">
                <a-button @click="pointConfirm" type="primary" :style="{ marginRight: '8px' }">
                    确认
                </a-button>
                <a-button @click="pointClose"> 关闭 </a-button>
        </div>
	</a-drawer>
  </div>
</template>

<script>
import { columns, pagination, formItem } from "./depend/config.js";
import {goodsInfo} from "@/api/shop/goods/index.js";
import { getPointList ,settingPoints, isEnableRedeem} from "@/api/shop/goods/index.js";
export default {
	data() {
		return {
			searchForm: {
				name: '',
				status: undefined,
				updateDateStart: '',
				updateDateEnd: '',
			},
			columns,
			goodsId:null,
			pagination,
			formItem,
			tableData: [],
			selectedRowKeys: [],
			//设置积分
			pointShow: false,
			activeId: undefined,
			pointForm: {
				points: 0
			},
			pointInfo: {},
		};
	},
	mounted() {
		this.getData();
	},
	methods: {
		getData() {
			getPointList({
				...this.searchForm,
				pageNum: this.pagination.current,
				size: this.pagination.pageSize,
			}).then((res) => {
				this.tableData = res.data.rows;
				this.pagination.total = res.data.total;
			});
		},
		search(data){
			this.searchForm = data;
			this.getData()
		},
		selectionChoosed(data) {
			this.selectedRowKeys = data;
		},
		handleTableChange(pagination) {
			const pager = { ...this.pagination };
			pager.current = pagination.current;
			pager.pageSize = pagination.pageSize;
			this.pagination = pager;
			this.getData();
		},
		handleMenuClick(data) {
			
		},
		addClose() {
			this.drawer.show = false;
			this.goodsId = null;
		},
		success() {
			this.getData();
		},
		//设置积分
		setPoint(val) {
			goodsInfo({appGoodsPushId: val.id}).then(res => {
				let data = res.data;
				this.pointInfo = data;
				this.pointForm.points = data.points
				this.activeId = data.id;
				this.pointShow = true
			})
		},
		pointConfirm() {
			let obj = {
				appGoodsPushId: this.activeId,
				points: this.pointForm.points
			}
			settingPoints(obj).then(res => {
				if(res.code === 200){
                    this.$message.success(res.msg);
                    this.pointClose()
                    this.getData()
                } else {
                    this.$message.error(res.msg);
                }
			})
		},
		//移除
		delPoint(val) {
			this.$confirm({
				title: "是否移除该商品？",
				icon: "delete",
				onOk: async () => {
					let res = await isEnableRedeem({ appGoodsPushId: val.id });
					if (res.code === 200) {
						this.$message.success(res.msg);
						this.getData();
					} else {
						this.$message.error(res.msg);
					}
				},
			});
		},
		pointClose() {
			this.activeId = undefined,
			this.pointForm.points = 0;
			this.pointInfo = {}
			this.pointShow = false
		}
	},
	computed: {
		hasSelected() {
			return this.selectedRowKeys.length > 0;
		},
	},
};
</script>

<style lang="less" scoped>
.table-img {
  width: 40px;
  height: 40px;
}
.item-name {
	padding: 16px 0px 16px 0px;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	text-transform: uppercase;
	color: #205FBD;
}
.info-img {
	height: 172px;
	width: 258px
}
</style>

export const columns = [
	{
		title: "商品状态",
		width: "6%",
		dataIndex: "status",
		customRender: function (status) {
			switch (status) {
			case false:
				return '已下架'
			case true:
				return '上架中'
			default:
				break;
			}
		}
	},
	{
		title: "商品名称",
		width: "18%",
		dataIndex: "skuName",
	},
	{
		title: "主图url",
		width: "4%",
		dataIndex: "mainPhoto",
		scopedSlots: { customRender: "mainPhoto"}
	},
	// {
	// 	title: "sku编码",
	// 	width: "10%",
	// 	dataIndex: "skuId",
	// },
	// {
	// 	title: "商品类型",
	// 	width: "4%",
	// 	dataIndex: "mallType",
	// 	customRender: function (mallType) {
	// 		switch (mallType) {
	// 		case 1:
	// 			return 'Jcook'
	// 		default:
	// 			break;
	// 		}
	// 	}
	// },
	// {
	// 	title: "店铺名",
	// 	width: "7%",
	// 	dataIndex: "shopName",
	// },
	// {
	// 	title: "品牌名",
	// 	width: "7%",
	// 	dataIndex: "brandName",
	// },
	// {
	// 	title: "供应商名",
	// 	width: "7%",
	// 	dataIndex: "vendorName",
	// },
	{
		title: "分类名称",
		width: "14%",
		dataIndex: "categoryFirstName",
		scopedSlots: { customRender: "type" },
	},
	{
		title: "售卖价（元）",
		width: "7%",
		dataIndex: "sellPrice",
	},
	{
		title: "商品积分",
		width: "7%",
		dataIndex: "points",
	},
	{
		title: "浏览量",
		width: "5%",
		dataIndex: "viewsNum",
	},
	{
		title: "积分兑换开启时间",
		width: "14%",
		dataIndex: "pointsCreateDate",
	},
	{
		title: "积分修改时间",
		width: "14%",
		dataIndex: "pointsUpdateDate",
	},
	{
		title: "操作",
		dataIndex: "action",
		key: "action",
		width: "250",
		fixed: "right",
		scopedSlots: { customRender: "action" },
	},
]
export const formItem = [
	{
		type: 'input',
		label:'商品名称',
		prop:'name',
		placeholder:'请输入'
	},
	{
		type: 'select',
		label:'商品状态',
		prop:'status',
		placeholder:'请选择',
		option:[{ id: 0,name:'已下架'},{ id: 1,name:'上架中'}],
	},
	{
		type: 'time',
		label:'更新时间',
		prop:'updateDate',
		start: 'updateDateStart',
		end:'updateDateEnd',
	},
]
export const pagination = {
	current: 1,
	total: 0,
	pageSize: 5,
	showTotal: (total) => `共 ${total} 条`,
	showSizeChanger: true,
	showQuickJumper: true,
}